


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}

.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
  }

  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }

  .upload-workers {
    /deep/ .el-upload {
      height: 35px !important;
    }
  }
}

.export-box {
  width: 100%;
  margin-bottom: 20px;

  h1 {
    padding: 0 5px 10px;
    display: flex;
    align-items: flex-end;
    font-size: 40px;
    border-bottom: 1px dashed #ccc;

    span {
      margin-left: 20px;
      font-size: 14px;
    }
  }

  >div {
    padding-left: 30px;
  }

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #fff;
    background: #c3c3c3;
    border: none;
  }
}
