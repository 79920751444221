.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.btnBox .el-button.is-disabled,
.btnBox .el-button.is-disabled:focus,
.btnBox .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.bgc-bv:hover,
.bgc-bv:focus,
.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  background-color: #5c6be8 !important;
}
.del-tishi .el-dialog__header {
  background: #ffffff;
  text-align: left;
}
.del-tishi .el-dialog__title {
  color: #333;
}
.del-tishi .el-dialog__body .theCountdown {
  color: red;
  font-size: 16px;
}
.del-tishi .el-dialog__headerbtn .el-dialog__close {
  color: black;
}
